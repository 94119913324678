<template>
  <div v-if="state == 'events'" class="ideace_home">
    <!-- <div class="p60 flex">
      <div class="section left">
        <h5>The Competitive Edge</h5>
        <h1 class="bold brown500 mt12">Hone your skills through competition</h1>
        <p style="margin-top: 16px">
          Participate in hundreds of events across multiple disciplines, 
          and accumulate recognition and awards along the way
        </p>
        <button class="black mt44">Participate Now</button>
      </div>
      <div class="section right ideace"></div>
    </div> -->
    <!-- <div class="ideace_hero" style=""></div> -->
    <div
      class="ideace_hero"
      v-if="pageInfo.banner"
      :style="{
        background:
          $mq != 'mobile'
            ? 'url(' +
              pageInfo.banner.url +
              ') top / contain no-repeat, url(../assets/arcause_final.jpg) top / contain no-repeat'
            : 'url(' +
              pageInfo.mobileBanner.url +
              ') top / contain no-repeat, url(../assets/arcause_mobile.jpg) top / contain no-repeat',
      }"
    ></div>
    <div class="wrap extended flex section2" style="z-index: -1">
      <div
        v-if="$mq != 'mobile'"
        style="padding: 40px 0px 40px 160px"
        class="section left flex vertical"
      >
        <h1 class="fs24">What is Ideace?</h1>
        <p
          class="mt20 fs14"
          v-html="
            pageInfo.description ||
            `Ethos, through its vertical, <a>iDeACE</a> works towards sensitizing students
          to the culture and the ethos that their designs would need to respond
          to. There is an effort to equip students to be agents of change, to
          lead, not just follow and to contribute towards building a harmonious
          society.`
          "
        ></p>
      </div>
      <!-- <div v-if="$mq != 'mobile'" class="hfill smear">
      </div>
      <div v-if="$mq != 'mobile'" class="cover"></div> -->
      <div class="hfill section right flex" style="position: relative">
        <!-- <h6 class="myauto white together-text mxauto" style="text-align: left; line-height: 2em">
          <span>Together</span> <br>
          <span class="brown600">Harnessing</span> Collaborative Strengths <br>
          <span class="brown600">of the</span> ACED Community <br>
          <span class="brown600">For</span> Our Cause <br>
          <span class="brown600">For Causes that</span> Align
        </h6> -->
        <div class="hands"></div>
      </div>
    </div>
    <page-header
      header="Let's participate!"
      :tabs="[
        {
          label: 'All',
          value: 'all',
        },
        {
          label: 'Competitions',
          value: 'competitions',
        },
        {
          label: 'Quizzes',
          value: 'quizzes',
        },
        {
          label: 'Scholarships',
          value: 'scholarships',
        },
        {
          label: 'Happenings',
          value: 'happenings',
        },
      ]"
      @tabClicked="
        substate = arguments[0].value;
        activeMasterEventId = '';
      "
      class="pb40"
    ></page-header>

    <div
      v-if="masterEvents.length > 0"
      class="me_chip_parent"
      style="border-bottom: 1px solid #eee"
    >
      <div class="flex wrap" style="flex-wrap: true">
        <div
          v-for="me in masterEvents"
          :key="me.id"
          :class="{ active: activeMasterEventId == me.id }"
          @click="
            activeMasterEventId != me.id
              ? (activeMasterEventId = me.id)
              : (activeMasterEventId = '')
          "
          class="me_chip"
        >
          {{ me.name }}
          <span v-if="eventCounts[me.id]">({{ eventCounts[me.id] }})</span>
        </div>
      </div>
      <div
        class="me_description"
        :class="{ active: activeMasterEventId }"
        v-if="activeMasterEvent"
        style="padding: 20px 24px"
      >
        <h2>What is {{ activeMasterEvent.name }}?</h2>
        <p v-html="activeMasterEvent.description" class="mt20"></p>
      </div>
    </div>

    <div class="mt12 px40 flex wrap">
      <div :class="[$mq == 'mobile' ? 'wfill' : '']" class="vertical flex">
        <label for="" style="color: transparent">Search</label>
        <div style="border: 1px solid #ccc; padding: 0 12px">
          <i class="ml8 search icon"></i>
          <input
            placeholder="Search"
            type="text"
            class="search_input"
            v-model="search"
          />
        </div>
      </div>
      <div
        :class="[$mq == 'mobile' ? 'wfill mt4' : 'ml12']"
        class="vertical flex"
      >
        <label for="">Activity</label>
        <select name="" id="" v-model="filter.activity">
          <option value="" selected>All Events</option>
          <option value="active">Active</option>
          <option value="past">Past</option>
          <option value="future">Future</option>
        </select>
      </div>
      <div
        :class="[$mq == 'mobile' ? 'wfill mt4' : 'ml12']"
        class="vertical flex"
      >
        <label for="">Registration</label>
        <select name="" id="" v-model="filter.registration">
          <option value="" selected>All Events</option>
          <option value="registered">Registered</option>
          <option value="not registered">Not Registered</option>
        </select>
      </div>
    </div>
    <div v-if="sortedEvents.length > 0" class="mt12 events px40 pb80">
      <div
        v-for="e in activeEvents"
        :key="`${e.title}-${e.id}`"
        @click="redirectToPage(e)"
        class="event_card"
      >
        <div v-if="submissionIds.includes(e.id)" class="brown500 label">
          Registered
        </div>
        <img
          class="img"
          v-lazy="
            e.type == 'livequiz' ? (e.logo || {}).url : (e.Poster || {}).url
          "
          :style="{
            objectFit: 'cover',
            objectPosition: 'center',
          }"
        />
        <div class="bottom">
          <h6
            :title="e.type == `livequiz` ? e.title : e.Title"
            style="font-size: 12px; margin-bottom: 12px"
          >
            {{ e.type == "livequiz" ? truncate(e.title) : truncate(e.Title) }}
          </h6>
          <div
            style="height: 60px; overflow: hidden"
            class="desc pb40"
            v-html="e.type == 'livequiz' ? e.description : e.Information"
          ></div>
          <p class="right pt8 mlauto brown500" style="font-size: 11px">
            ...Know More
          </p>
          <!-- <a class="mt4 flex">
            Learn more
            <i style="font-size: 12px; margin: auto 4px;" class="arrow right icon"></i>
          </a> -->
        </div>
      </div>
      <div class="division">
        <div class="line"></div>
        <div class="pill">Past Events</div>
      </div>

      <div
        v-for="e in pastEvents"
        :key="`${e.title}-${e.id}`"
        @click="redirectToPage(e)"
        class="event_card"
      >
        <div v-if="submissionIds.includes(e.id)" class="brown500 label">
          Registered
        </div>
        <img
          class="img"
          v-lazy="
            e.type == 'livequiz' ? (e.logo || {}).url : (e.Poster || {}).url
          "
          :style="{
            objectFit: 'cover',
            objectPosition: 'center',
          }"
        />
        <div class="bottom">
          <h6
            :title="e.type == `livequiz` ? e.title : e.Title"
            style="font-size: 12px; margin-bottom: 12px"
          >
            {{ e.type == "livequiz" ? truncate(e.title) : truncate(e.Title) }}
          </h6>
          <div
            style="height: 60px; overflow: hidden"
            class="desc pb40"
            v-html="e.type == 'livequiz' ? e.description : e.Information"
          ></div>
          <p class="right pt8 mlauto brown500" style="font-size: 11px">
            ...Know More
          </p>
          <!-- <a class="mt4 flex">
            Learn more
            <i style="font-size: 12px; margin: auto 4px;" class="arrow right icon"></i>
          </a> -->
        </div>
      </div>
      <div
        v-for="e in searchedOldEvents"
        :key="`${e.name}-${e.id}`"
        @click="redirectToPage(e, true)"
        class="event_card"
      >
        <img
          class="img"
          v-lazy="getOldEventPoster(e)"
          :style="{
            objectFit: 'cover',
            objectPosition: 'center',
          }"
        />
        <div class="bottom">
          <h6 style="font-size: 12px; margin-bottom: 12px">{{ e.name }}</h6>
          <div
            style="height: 60px; overflow: hidden"
            class="desc pb40"
            v-html="e.description"
          ></div>
          <p class="right pt8 mlauto brown500" style="font-size: 11px">
            ...Know More
          </p>
        </div>
      </div>
    </div>
    <div v-else class="px40 pb80">
      <h3
        class="center mt32"
        style="font-style: italic; text-transform: capitalize"
      >
        <span v-if="loaded == false">Loading</span>
        <span v-else>No {{ substate == "all" ? "Events" : substate }}</span>
      </h3>
    </div>
  </div>
</template>

<style lang="css">
.search_input {
  height: 34px;
  font-size: 12px;
  border: none;
}
.search_input:focus {
  outline: none;
}

.division {
  width: calc(100% - 40px);
  height: 24px;
  margin-bottom: 12px;
  margin-top: 8px;
}
.division .line {
  width: 100%;
  height: 1px;
  background: #eee;
  position: relative;
  top: 16px;
}
.division .pill {
  padding: 4px 12px;
  width: 120px;
  background: #eee;
  color: #333;
  position: absolute;
  left: calc(50% - 60px);
  text-align: center;
  right: 50%;
  font-size: 12px;
  border-radius: 40px;
}
</style>

<script>
import pageHeader from "../components/page-header";
import moment from "moment";
import showdown from "showdown";
import $ from "jquery";
export default {
  name: "events",
  components: { pageHeader },
  metaInfo() {
    return {
      title: this.pageInfo?.SEO?.metaTitle || "Ethos | Ideace",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content:
            this.pageInfo?.SEO?.metaDescription ||
            "Design and architecture competitions, quizzes, workshops & events for students & professionals of the Architecture, Construction, Engineering & Design (ACED) fraternity",
        },
        {
          hid: "keywords",
          name: "keywords",
          content: this.pageInfo?.SEO?.metaKeywords || "",
        },
      ],
    };
  },
  data() {
    return {
      state: "events",
      substate: "all",
      pageInfo: {},
      events: [],
      activeMasterEventId: "",
      masterEvents: [],
      quizzes: [],
      filter: {
        registration: "",
        activity: "",
      },
      oldEvents: [],
      submissions: [],
      ix: undefined,
      search: "",
      loaded: false,
    };
  },
  watch: {
    state: function () {
      $(document).scrollTop(0);
    },
    filter: function () {
      this.activeMasterEventId = "";
    },
    substate: function () {
      this.activeMasterEventId = "";
    },
  },
  computed: {
    submissionIds: function () {
      return this.submissions.map((sub) => sub.event?.id);
    },
    eventCounts: function () {
      let counts = {};
      [...this.events, ...this.oldEvents]
        .filter((e) => e.master_event && !e?.hidden)
        .forEach((e) => {
          counts[e.master_event.id] = counts[e.master_event.id] || 0;
          counts[e.master_event.id] += 1;
        });
      return counts;
    },
    activeMasterEvent: function () {
      if (!this.activeMasterEventId) return null;
      return this.masterEvents.find((me) => me.id == this.activeMasterEventId);
    },
    active: function () {
      return this.sortedEvents[this.state];
    },
    activeEvents: function () {
      return this.sortedEvents.filter((e) => {
        if (!e.End && moment(e.Start).unix() <= moment().unix()) {
          return true;
        }
        return moment(e.End).unix() >= moment().unix();
      });
    },
    pastEvents: function () {
      return this.sortedEvents.filter((e) => {
        return moment(e.End).unix() < moment().unix();
      });
    },
    searchedOldEvents: function () {
      if (this.substate != "all") {
        return [];
      }
      let events = [...this.oldEvents];
      if (this.search) {
        events = events.filter((e) => {
          let title = e.name || e.Title;
          let description = e.description || e.Information;
          return (
            title?.toLowerCase()?.includes(this.search.toLowerCase()) ||
            description?.toLowerCase()?.includes(this.search.toLowerCase())
          );
        });
      }
      if (this.activeMasterEventId) {
        events = events.filter(
          (e) => (e.master_event || {}).id == this.activeMasterEventId
        );
      }
      return events;
    },
    sortedEvents: function () {
      let events = [
        ...this.events,
        ...this.quizzes.map((q) => {
          q.type = "livequiz";
          return q;
        }),
      ];
      // Check if user is an admin
      if (this.$bus.user?.role?.name == "Website-Admin") {
        // Do nothing
      } else {
        events = events.filter((ev) => !ev.hide && !ev.hideOnIdeace);
      }

      let substate = this.substate;
      switch (substate) {
        case "competitions":
          events = events.filter((e) => e.type == "event");
          break;
        case "scholarships":
          events = events.filter((e) => e.type == "scholarship");
          break;
        case "happenings":
          events = events.filter((e) => e.type == "other");
          break;
        case "quizzes":
          events = events.filter(
            (e) => e.type == "quiz" || e.type == "livequiz"
          );
          break;
      }

      if (this.activeMasterEventId) {
        events = events.filter(
          (e) => (e.master_event || {}).id == this.activeMasterEventId
        );
      }

      let f = this.filter;
      switch (f.registration) {
        case "registered":
          events = events.filter((e) => this.submissionIds.includes(e.id));
          break;
        case "not registered":
          events = events.filter((e) => !this.submissionIds.includes(e.id));
          break;
        default:
          break;
      }
      switch (f.activity) {
        case "active":
          events = events.filter((e) => {
            return (
              moment(e.Start).unix() <= moment().unix() &&
              moment(e.End).unix() >= moment().unix()
            );
          });
          break;
        case "past":
          events = events.filter((e) => {
            return moment(e.End).unix() < moment().unix();
          });
          break;
        case "future":
          events = events.filter((e) => {
            return moment(e.Start).unix() > moment().unix();
          });
      }

      if (this.search) {
        events = events.filter((e) => {
          let title = e.title || e.Title;
          let description = e.description || e.Information;
          return (
            title?.toLowerCase()?.includes(this.search.toLowerCase()) ||
            description?.toLowerCase()?.includes(this.search.toLowerCase())
          );
        });
      }

      return events.sort((e1, e2) => {
        if (e1.priority != e2.priority) {
          let e1priority = e1.priority || 10;
          let e2priority = e2.priority || 10;
          return e1priority - e2priority;
        }
        let e1Start = e1.Start || e1.validFrom;
        let e2Start = e2.Start || e2.validFrom;
        return moment(e2Start).unix() - moment(e1Start).unix();
      });
    },
  },
  async mounted() {
    this.$req
      .get("master-events?_limit=-1")
      .then((r) => {
        this.masterEvents = r.data;
      })
      .catch((e) => console.log(e));

    this.$req
      .get("ideacepage")
      .then((r) => {
        this.pageInfo = r.data;
      })
      .catch((e) => console.log(e));

    // @TODO Old events load on scroll past event count
    this.$req
      .get("old-events?_limit=200", this.$bus.headers)
      .then((r) => {
        this.oldEvents = r.data.sort((e1, e2) => {
          return e2.year - e1.year;
        });
      })
      .catch((e) => console.log(e));

    this.$req
      .get("events?_limit=-1", {
        cache: {
          maxAge: 2 * 60 * 1000, // 2 min instead of 15 min
          exclude: { query: false },
        },
      })
      .then((r) => {
        this.events = r.data.sort(
          (e1, e2) => moment(e2.start).unix() - moment(e1.start).unix()
        );
        this.loaded = true;
      })
      .catch((e) => console.log(e));
    this.$req
      .post("runQuery", {
        query: "quizzes",
      })
      .then((r) => {
        this.quizzes = r.data.sort(
          (e1, e2) => moment(e2.start).unix() - moment(e1.start).unix()
        );
      })
      .catch((e) => console.log(e));
    if (this.$bus.user) {
      this.$req
        .get(`event-submissions?users.id=${this.$bus.user.id}&_limit=-1`, {
          headers: {
            Authorization: `Bearer ${this.$bus.token}`,
          },
        })
        .then((r) => {
          this.submissions = r.data;
        })
        .catch((e) => console.log(e));
    }
  },
  methods: {
    truncate: function (string) {
      return string.length > 20 ? string.substring(0, 20) + "..." : string;
    },
    parseMarkdown: function (info) {
      // eslint-disable-next-line no-undef
      var converter = new showdown.Converter(),
        text = info,
        html = converter.makeHtml(text);
      return html;
    },
    getOldEventPoster: function (oldEvent) {
      let files = oldEvent.files;
      let poster = files.find((f) => f.Type == "poster");
      if (poster) {
        if (poster.Files.length > 0) {
          return poster.Files[0].url;
        } else if (poster.Url && poster.Url.includes("http")) {
          return poster.Url;
        } else if (poster.Url) {
          return (
            "https://ethosstrapibucket.s3.ap-south-1.amazonaws.com/eventfiles/" +
            encodeURIComponent(poster.Url)
          );
        }
      }
      return "";
    },
    formattedDate: function (from, to) {
      from = moment(from).format("Do MMM YYYY");
      to = moment(to).format("Do MMM YYYY");
      return from + " to " + to;
    },
    abbreviate: function (info) {
      return info.slice(0, 160);
    },
    redirectToPage: function (e, old = false) {
      if (e.type == "livequiz") {
        this.$router.push(`/ideace/quizzes/${e.id}`);
        return;
      }
      if (old) {
        this.$router.push(`/ideace/${e.name}`);

        return;
      }
      // this.$router.push(`/ideace/${e.Title}`);
      if (e.pageName)
        this.$bus.redirect(
          `https://ethosempowers.com/ideace/events/${e.pageName}`,
          false,
          true
        );
      else
        this.$bus.redirect(
          `https://ethosempowers.com/ideace/${e.Title}`,
          false,
          true
        );
    },
  },
};
</script>

<style lang="css">
.ideace_home {
  width: 100%;
  position: relative;
  padding: 0px;
  overflow: hidden;
}
.section2 {
  height: 200px;
}
@media (max-width: 800px) {
  .section2 {
    height: fit-content;
  }
}
.section.right {
  /* background:  url('../assets/smear.svg'); */
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: top;
  padding: 0px;
  width: 60%;
}
.ideace_hero {
  height: 37vw;
  width: 100%;
  /* background: url("../assets/ideace_final.jpg") top / contain no-repeat; */
}

@media (max-width: 700px) {
  .ideace_hero {
    height: 100vw;
    /* background: url("../assets/ideace_mobile.jpg") top / contain no-repeat; */
  }
}
.events {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  position: relative;
  justify-content: flex-start;
}
.event_card {
  border: 1px solid #eee;
  width: calc(220px - 20px);
  margin: 12px 20px 12px 0px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: fit-content;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  height: 340px;
}
.event_card .label {
  color: white;
  right: -8px;
  top: 20px;
  position: absolute;
  padding: 4px 12px;
  border-radius: 2px;
  font-size: 11px;
}
.event_card .desc * {
  font-size: 11px;
  font-weight: 400;
}
@media (max-width: 800px) {
  .event_card {
    width: 100%;
    margin: 12px 0px;
  }
}
.event_card .img {
  width: 100%;
  min-height: 200px;
}
.event_card .bottom {
  width: 100%;
  padding: 20px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}
.bottom p {
  margin: 0px;
  font-size: 14px;
}
.fadeInUp {
  animation: fadeInUp 0.6s;
}
@keyframes fadeInUp {
  0% {
    top: 12px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}

.extended {
  width: calc(100% + 200px);
  margin-left: -100px;
}

@media (max-width: 800px) {
  .extended {
    width: calc(100% + 120px);
    margin-left: -60px;
  }
}
.section.left {
  width: 50%;
  position: relative;
}
.section.right {
  display: flex;
  flex-direction: column;
  justify-content: top;
  padding: 20px;
  width: 50%;
}

.mobile-center {
  text-align: initial;
}

@media (max-width: 800px) {
  .section.left {
    width: 100%;
    position: relative;
  }
  .section.right {
    width: 100%;
    position: relative;
  }
  .mobile-center {
    text-align: center;
  }
}

.me_chip_parent {
  padding: 12px;
}

.me_chip {
  background: #f5f5f5;
  border-radius: 40px;
  color: #585757;
  cursor: pointer;
  width: fit-content;
  padding: 6px 18px;
  margin: 12px 0px 12px 20px;
  transition: 0.2s;
  font-weight: 600;
  border: 2px solid transparent;
}
.me_chip:hover {
  background: #fdf7f0;
  color: #6b3e1e;
}
.me_chip.active,
.me_chip:active {
  background: #fdf7f0;
  border: 2px solid #6b3e1e;
  color: #6b3e1e;
}
.me_description {
  max-height: 0px;
  height: auto;
  transition: 0.4s;
}
.me_description.active {
  max-height: 1000px;
}
</style>
